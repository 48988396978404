<template>
  <div id="early-warning-system-page">
    <b-card no-body>
      <b-card-header>
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-exclamation-circle"></i>
          <span style="margin-left: 5px">
            Early Warning System Penghentian Scraper
          </span>
        </b-card-title>
        <div
          class="d-flex flex-wrap gap-5px justify-content-end align-items-center"
        >
          <!-- tier filter -->
          <div>
            <select
              v-model="filter_data.tier"
              class="form-control"
              @change="(pagination.page = 1), getData()"
            >
              <option value="">Semua Tier</option>
              <option value="0">Untier</option>
              <option value="1">Tier 1</option>
              <option value="2">Tier 2</option>
              <option value="3">Tier 3</option>
              <option value="4">Tier 4</option>
            </select>
          </div>
          <!-- keyword filter -->
          <b-input-group class="input-group-merge" style="width: 20rem">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter_data.key"
              placeholder="Masukkan kata kunci..."
            />
          </b-input-group>
        </div>
      </b-card-header>
      <b-card-body>
        <b-tabs v-model="current_tab">
          <b-tab>
            <template #title>
              <i class="bi bi-slash-circle "></i>
              <span v-b-tooltip.hover.v-dark="no_article_description">
                Tidak Mempublikasikan Berita
              </span>
            </template>
            <loader v-if="is_loading" class="mt-5" />
            <dataTable
              v-else
              :data="early_warning_data"
              :action_button="early_warning_data.action_button"
              :activateDag="activateDag"
              :is_pagination="false"
            />
            <paginationInfo
              :page="pagination.page"
              :items="pagination.items"
              :count="pagination.count"
              @update-value="paginationChanged"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <i class="bi bi-speedometer"></i>
              <span v-b-tooltip.hover.v-dark="unusable_description">
                Tidak Masuk Dashboard
              </span>
            </template>
            <loader v-if="is_loading" class="mt-5" />
            <dataTable
              v-else
              :data="early_warning_data"
              :action_button="early_warning_data.action_button"
              :activateDag="activateDag"
              :is_pagination="false"
            />
            <paginationInfo
              :page="pagination.page"
              :items="pagination.items"
              :count="pagination.count"
              @update-value="paginationChanged"
            />
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import $ from "jquery";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import {
  BTabs,
  BTab,
  BCardText,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  VBTooltip,
} from "bootstrap-vue";
import dataTable from "@/views/components/dataTable.vue";
import paginationInfo from "../components/paginationInfo.vue";
import loader from "@/views/components/loader.vue";
import {
  confirmAction,
  getErrorMessage,
  showActionResult,
  showLoaderAlert,
} from "@/helper/helper";

export default {
  name: "EarlyWarningSystem",
  components: {
    BTabs,
    BCardText,
    BTab,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    paginationInfo,
    dataTable,
    VBTooltip,
    loader,
  },
  data() {
    return {
      cancel_token: null,
      is_loading: true,
      no_article_description:
        "Tidak mempublikasikan berita selama 2 bulan terakhir",
      unusable_description: "Tidak masuk ke dashboard selama 6 bulan terakhir",
      current_tab: 0,
      filter_data: {
        key: "",
        tier: "",
      },
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      no_article_field: [
        {
          key: "no",
          label: "no",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "1rem" },
        },
        {
          key: "_id",
          label: "nama scraper",
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "file_name",
          label: "nama file",
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "domain",
          label: "domain",
          thClass: "text-justify",
          tdClass: "text-justify",
        },
        {
          key: "tier",
          label: "tier",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "cakupan",
          label: "cakupan",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "action",
          label: "aksi",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      unusable_field: [
        {
          key: "no",
          label: "no",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "1rem" },
        },
        {
          key: "_id",
          label: "nama scraper",
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "file_name",
          label: "nama file",
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "domain",
          label: "domain",
          thClass: "text-justify",
          tdClass: "text-justify",
        },
        {
          key: "tier",
          label: "tier",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "cakupan",
          label: "cakupan",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "last_streams_inserted",
          label: "terakhir masuk dashboard",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "action",
          label: "aksi",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      early_warning_data: {
        page_info: {
          page: this.$route.query.page ? this.$route.query.page : 1,
          limit: this.$route.query.items ? this.$route.query.items : 10,
          total: 0,
        },
        fields: [],
        items: [],
        action_button: {
          dag: true,
        },
      },
    };
  },
  watch: {
    current_tab() {
      this.pagination.page = 1;
      this.getData();
    },
    "filter_data.key"() {
      this.pagination.page = 1;
      if (
        this.filter_data.key.trim().length == 0 ||
        this.filter_data.key.trim().length >= 2
      ) {
        this.getData();
      }
    },
  },
  methods: {
    paginationChanged(data) {
      this.pagination = data;
      this.getData();
    },
    getEarlyWarningData(type = "no_article") {
      this.is_loading = true;
      if (type == "no_article") {
        this.early_warning_data.fields = this.no_article_field;
      } else {
        this.early_warning_data.fields = this.unusable_field;
      }

      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();
      let params = {
        type: type,
        page: this.pagination.page,
        items: this.pagination.items,
        ...(this.filter_data.tier ? { tier: this.filter_data.tier } : {}),
        ...(this.filter_data.key ? { key: this.filter_data.key.trim() } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "early-warning-system?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_token.token })
        .then((res) => {
          this.cancel_token.value = null;
          this.early_warning_data.items = res.data.early_warning_data || [];
          this.pagination.count = res.data.pagination_info.count || 0;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
    getData() {
      if (this.current_tab == 0) {
        this.getEarlyWarningData("no_article");
      } else {
        this.getEarlyWarningData("unusable");
      }
    },
    async activateDag(id, status) {
      const title = status == 1 ? "Nonaktifkan DAG?" : "Aktifkan DAG?";
      const subtitle = `DAG dari Scraper "${id}" akan ${
        status == 1
          ? "Dinonaktifkan. Mohon Masukkan Keterangan!"
          : "Diaktifkan!"
      }`;
      const confirm_button = `Ya, ${status == 1 ? "Nonaktifkan" : "Aktifkan"}!`;
      const default_description =
        this.current_tab == 0
          ? this.no_article_description
          : this.unusable_description;
      const is_confirmed = await confirmAction(
        title,
        subtitle,
        confirm_button,
        "question",
        true,
        default_description
      );
      if (is_confirmed) {
        showLoaderAlert();
        let params = {
          id: id,
          description: is_confirmed,
          is_paused: status == 1 ? true : false,
        };
        let query = Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
        let api =
          process.env.VUE_APP_API_URL + "early-warning-system/update?" + query;

        useJwt.axiosIns
          .put(api)
          .then(() => {
            showActionResult(
              `DAG Berhasil ${status == 1 ? "Dinonaktifkan!" : "Diaktifkan!"}`
            );
            this.getData();
          })
          .catch((err) => {
            const err_message = getErrorMessage(err);
            showActionResult(err_message, "error");
          });
      }
    },
  },
  mounted() {
    this.getEarlyWarningData();
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
